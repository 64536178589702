.react-calendar-heatmap text {
  font-size: 10px;
  fill: #555;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect:hover {
  stroke: #555;
  stroke-width: 1px;
}

.react-calendar-heatmap .color-empty {
  fill: #eeeeee;
}

.react-calendar-heatmap .color-scale-1 {
  fill: #e24d42;
}
.react-calendar-heatmap .color-scale-2 {
  fill: #e6c33b;
}
.react-calendar-heatmap .color-scale-3 {
  fill: #adc744;
}
.react-calendar-heatmap .color-scale-4 {
  fill: #3bcc6e;
}
