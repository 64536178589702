@import '~antd/dist/antd.css';

.livee-purple {
  background-color: rgb(154, 18, 179) !important;
}

._loading_overlay_overlay {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
